#MusicPlayer {
    text-align: center;
    box-sizing: border-box;
    margin: 8px;
    font-size: 14px;
}

.music-player-container {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#song-bg-image {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    position: absolute;
    box-shadow: none;
}

@media all and (orientation:portrait) {
    #MusicPlayer {
        height: calc(100vw - 36px);
        width: calc(100vw - 36px);
    }
    
    #song-bg-image {
        height: calc(100vw - 36px);
        width: calc(100vw - 36px);
    }

    .song-cover {
        width: calc((100vw - 36px) / 2);
        height: calc((100vw - 36px) / 2);
    }

    .song-title {
        font-size: 4vw;
    }
}

@media all and (orientation:landscape) {
    #MusicPlayer {
        height: 60vh;
        width: 60vh;
    }
    
    #song-bg-image {
        height: 60vh;
        width: 60vh;
    }

    .song-cover {
        width: 30vh;
        height: 30vh;
    }
}

.song-metadata {
    text-align: center;
    text-shadow: 1px 1px 2px black;
}

.song-cover {
    margin-top: 10px;
}

.player-icon {
    filter: drop-shadow(0px 0px 6px black);
}

.song-play-button {
    background: none;
    border: none;
    align-items: center;
    justify-content: center;
    color: white;
    filter: drop-shadow(0px 0px 6px black);
    transition: all .2s ease-in-out;
}

.song-play-button:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.song-play-button:disabled {
    color: lightgray;
}

.song-play-button:disabled:hover {
    transform: none;
    cursor: not-allowed;
}

.song-title {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.song-sub-title {
    margin-top: -0.5em;
    color: lightgray;
}

.song-time {
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    color: lightgray;
    font-size: smaller;
    text-shadow: 1px 1px 2px black;
}

.song-time-stamp {
    margin-top: 1em;
}

.song-info-container {
    text-align: center;
}

.song-timeline {
    width: 80%;
    text-align: center;
    margin: 0 auto;
}

.song-control-container {
    display: flex;
    margin: 0 auto;
    align-items: stretch;
}

.song-metadata-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    gap: 15px;
}

@media (min-width: 600px) {
    #MusicPlayer {
        font-size: 16px;
    }

    .song-metadata-container {
        flex-direction: column;
        gap: 0;
        margin-bottom: 14px;
    }

    .song-control-container {
        width: 80%;
    }
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 7px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-image: linear-gradient(white, white);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 6px black;
}

.song-volume-button {
    background: none;
    border: none;
    align-items: center;
    justify-content: center;
    color: white;
    flex: 0.25;
}

.song-volume-button:hover {
    cursor: pointer;
}

.flex-grid-item {
    align-items: center;
    flex: 1;
}

.volume-control-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.volume-slider {
    width: 100px;
    flex: 1;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: none;
    background: white;
    cursor: pointer;
    box-shadow: 0px 0px 6px black;
    transition: background .3s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: none;
    background: white;
    cursor: pointer;
    box-shadow: 0px 0px 6px black;
    transition: background .3s ease-in-out;
}

input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    border: none;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    box-shadow: 0px 0px 6px black;
    transition: background .3s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
    background: #161b22;
}

input[type="range"]::-moz-range-thumb:hover {
    background: #161b22;
}

input[type="range"]::-ms-thumb:hover {
    background: #161b22;
}

/* Input Track */
input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

input[type=range]::-moz-range-track {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

input[type="range"]::-ms-track {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}