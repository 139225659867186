#Home {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#home-search {
    width: min(500px, 75vw);
    display: inline-block;
}
