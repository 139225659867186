#post-container {
    background-color: #161b22;
    position: relative;
    left: 50%;
    top: 10%;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: inline-block;
}

#post-container-media-container {
    display: flex;
    justify-content: center;
    min-width: 350px;
}

@media all and (orientation:landscape) {
    #post-container img {
        max-width: calc(100vw - 20px);
        max-height: 63vh;
    }

    #post-container .video-container {
        width: 60vw;
        height: 33.75vw;
    }

    #post-information-container {
        background-color: #161b22;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-top: 20px;
        padding: 10px;
        width: 95%;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
        position: relative;
    }
}

@media all and (orientation:portrait) {
    #post-container img {
        max-width: calc(100vw - 20px);
        max-height: 63vh;
    }

    #post-container .video-container {
        width: calc(100vw - 20px);
        height: calc((100vw - 20px) / (16 / 9));
    }

    #post-information-container {
        background-color: #161b22;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-top: 10px;
        padding: 10px;
        width: calc(100vw - 20px);
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
        position: relative;
    }
}

#post-container-top-row {
    display: flex;
    width: 100%;
}

#navigate-buttons {
    display: flex;
    margin-left: auto;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

#post-container-bottom-row {
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
}

.post-container-information {
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
}
