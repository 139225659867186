#AddToCollectionDialogue {
    width: 100%;
    min-width: 35vw;
    height: 100%;
}

#add-to-collection-search-box {
    width: 100%;
}

#add-to-collection-list {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    overflow-y: auto;
}

#add-to-collection-pagination {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    color: white;
}
