#image-wall {
    position: absolute;
    width: 100%;
}

#image-wall-container {
    background-color: #161b22;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: 25px;
    margin-right: 25px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    margin-bottom: 60px;
}

@media (min-width: 775px) {
    #image-wall-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1150px) {
    #image-wall-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1530px) {
    #image-wall-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1910px) {
    #image-wall-container {
        grid-template-columns: repeat(5, 1fr);
    }
}

#page-button-container {
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 10, 20, 0.9);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
}

#page-grid-pagination-container {
    justify-self: center;
}

.page-button {
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    width: 50px;
    background-color: transparent;
    color: white;
}

.page-button:hover:enabled {
    background-color: white;
    color: black;
    cursor: pointer;
}

.page-button:disabled {
    display: none;
}

.page-button-selected {
    background-color: white;
    color: black;
}

.thumbnail_image {
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: max-content;
    height: max-content;
    max-width: 320px;
    max-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbnail_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 162px;
}

.thumbnail_image a {
    display: flex;
}

.thumbnail_image img {
    transition: all .2s ease-in-out;
}

.paginated_grid_view_item_wrapper_flexbox {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    height: 100%;
    width: fit-content;
    flex-direction: column;
    max-width: 288px;
    min-width: 125px;
}

.paginated_grid_view_item_button:hover img {
    transform: scale(1.5);
}

.thumb-img {
    max-width: 288px;
    max-height: 162px;
}

.lazy-load-image-background {
    color: inherit !important;
    width: 192px;
    height: 192px;
}

.lazy-load-image-background img {
    transition: all .2s ease-in-out !important;
}

.lazy-load-image-loaded {
    width: inherit;
    height: inherit;
}

.MuiImageList-standard {
    width: 100%;
    margin-block-start: 0;
    margin-block-end: 0;
}

.MuiImageListItem-standard {
    text-align: center;
    justify-content: center;
    border-radius: 5px;
}

.paginated_grid_view_item_footer {
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
    width: 100%;
}

.MuiImageListItemBar-positionBelow {
    width: 100%;
}

.MuiImageListItemBar-titleWrapBelow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    text-align: left;
}

.MuiImageListItem-standard a {
    color: white;
    text-decoration: none;
}

.lazy-load-image-loaded {
    line-height: 0;
}

#selection-action-tool-row {
    text-align: right;
}

.paginated_grid_view_item_footer_button {
    position: absolute;
    right: 0;
    top: 0;
}
