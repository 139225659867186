#upload-progress {
    min-width: 250px;
}

#tag-editor-div {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
}

#broker-selector {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
}

.file-selector-row {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin-top: 10px;
}
